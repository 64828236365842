<template>
  <div v-show="isAllowed" @click="openModal" class="btn btn-primary">{{ buttonName }}</div>
</template>

<script>
import ButtonMixin from '../mixins/buttonMixin';
export default {
  name: 'tour-accept-btn',
  props: {
    allowedActions: {
      required: true,
      validator: (value) => {
        if (value !== null) {
          return value.constructor.name == 'Array';
        } else {
          return true;
        }
      },
    },
    type: {
      validator: (value) => {
        return typeof value === 'string' || value === null;
      },
      required: true,
    },
    applicationUuid: {
      validator: (value) => {
        return typeof value === 'string' || value === null;
      },
      required: true,
    },
  },
  mixins: [ButtonMixin],
  data() {
    return {
      actionName: 'ACCEPT',
      actionType: 'TOUR_PROG',
    };
  },
  watch: {
    checkBankAccountInbox(val) {
      if (val && this.isAllowed) {
        this.openModal();
      }
    },
  },
  computed: {
    // checkBankAccountInbox: {
    //   get() {
    //     return this.$store.state.v2.profile.checkBankAccountInbox;
    //   },
    //   set(val) {
    //     this.$store.commit('v2/profile/SET_CHECK_BANK_ACC_INBOX', val);
    //   },
    // },
  },
  methods: {
    async openModal() {
      this.$modal.show('modal-accept-tour', {
        tourProgHeaderUuid: this.applicationUuid,
        buttonName: this.buttonName,
      });
    },
  },
};
</script>

<style scoped></style>
